import { Spin } from 'antd';
import classNames from 'classnames';
import { max, sumBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '~common/commonFunctions';
import { IBasicItem } from '~common/interfaces';
import { TextTruncate } from '~components';
import { FacilityBookingStatus } from '~features/facility-booking/constants';
import { IFacilityBookingScheduleItem } from '~features/facility-booking/interfaces';
import { BookingDetail } from '~features/facility-booking/pages/FacilitySchedulePage/components/BookingDetail/BookingDetail';
import { BookingList } from '~features/facility-booking/pages/FacilitySchedulePage/components/ScheduleWeekView/BookingList';
import { IndicatorType } from '~features/indicator/constants';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';
import { DetailBookingModal } from '~features/room-booking/pages/SchedulePage/components/DetailBookingModal/DetailBookingModal';
import { ReactComponent as CleanRoomIcon } from '~assets/images/clean-room.svg';
import { WarningFilled } from '@ant-design/icons';
import localStorageAuthService from '~common/authStorage';
import {
    RoomManagementBackgroundColor,
    RoomManagementStatus,
    RoomManagementStatusColor,
} from '~features/room-management/constants';
import { convertToBookingSchedule } from '~features/room-management/helper';
import { IUnassignedBookingItem } from '~features/room-management/interfaces';
import {
    getBookingUnassignedList,
    roomManagementSelector,
    unassignedBookingSuccess,
    setAssignRoomLoading,
    removeBookingFromAssignList,
    changeBookingStatusSuccess,
} from '~features/room-management/reducers/room-management.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { RoomStopSellingDetail } from '../RoomDetail/RoomStopSellingDetail';
import { RoomVacancyDetail } from '../RoomDetail/RoomVacancyDetail';
import { DropBox } from './DropBox';
import './SettingCell.scss';
import { parseDate } from '~plugins/dayjs';
import { useTranslation } from 'react-i18next';
import { checkUserPermission } from '~common/commonFunctions';
import { UserGroup } from '~common/constants';

type Props = {
    columnIndex: number;
    rowIndex: number;
    validRooms: number[];
    isReadOnly?: boolean;
    onChangeStatusSuccess?: (booking: IFacilityBookingScheduleItem) => void;
};

export const SettingCell = (props: Props) => {
    const { t } = useTranslation();
    const { columnIndex, rowIndex, validRooms, isReadOnly, onChangeStatusSuccess } =
        props;
    const dispatch = useAppDispatch();
    const [isShowDetail, setIsShowDetail] = React.useState(false);
    const {
        assignedList,
        isDraggingBooking,
        assignRoomLoading,
        currentStatus,
        facilityBookings,
        currentDate,
    } = useAppSelector(roomManagementSelector);
    const navigate = useNavigate();
    const hotelStored = localStorageAuthService.getSelectedHotel();

    const isHotelCleaner = useMemo(() => {
        return checkUserPermission([UserGroup.HOTEL_CLEANING]);
    }, []);

    const room = useMemo(() => {
        const _room = assignedList[`${rowIndex}-${columnIndex}`];
        return _room;
    }, [assignedList, rowIndex, columnIndex]);

    const isRoomIndicatorType = useMemo(() => {
        return room?.type === IndicatorType.ROOM;
    }, [room]);

    const isFacilityIndicatorType = useMemo(() => {
        return room?.type === IndicatorType.FACILITY;
    }, [room]);

    const bookings = useMemo(() => {
        if (isRoomIndicatorType) return [];
        return facilityBookings['facility-' + room?.id] || [];
    }, [facilityBookings, room, isRoomIndicatorType]);

    const isOpacityFacilityBookingByStatus = useCallback(
        (status: string) => {
            if (bookings.some((booking) => booking.status === status)) {
                return false;
            }
            return true;
        },
        [bookings],
    );

    const isOpacityBookingByStatus = useCallback(
        (status: string) => {
            if (room?.booking?.bookingStatus === status) {
                return false;
            }
            return true;
        },
        [room],
    );

    const isOpacityRoomIndicator = useCallback(() => {
        switch (currentStatus) {
            case RoomManagementStatus.ALL:
                return false;
            case RoomManagementStatus.VACANCY:
            case RoomManagementStatus.STOP_SELLING:
                if (room?.status === currentStatus) {
                    return false;
                }
                return true;
            case RoomBookingItemBookingStatus.NOT_ARRIVED:
            case RoomBookingItemBookingStatus.CHECKED_IN:
            case RoomBookingItemBookingStatus.CHECKED_OUT:
                return isOpacityBookingByStatus(currentStatus);
            default:
                return true;
        }
    }, [room, currentStatus, isOpacityBookingByStatus]);

    const isOpacityFacilityIndicator = useCallback(() => {
        switch (currentStatus) {
            case RoomManagementStatus.ALL:
                return false;
            case RoomManagementStatus.STAY:
                return !bookings.length;
            case RoomManagementStatus.VACANCY:
                if (room?.status === currentStatus) {
                    return false;
                }
                return true;
            case FacilityBookingStatus.FINISHED:
                return isOpacityFacilityBookingByStatus(currentStatus);
            default:
                return true;
        }
    }, [room, currentStatus, bookings, isOpacityFacilityBookingByStatus]);

    const isOpacity = useMemo(() => {
        if (!room) return true;
        if (isRoomIndicatorType) {
            return isOpacityRoomIndicator();
        }
        return isOpacityFacilityIndicator();
    }, [room, isRoomIndicatorType, isOpacityRoomIndicator, isOpacityFacilityIndicator]);

    const isStay = useMemo(() => {
        return room?.status === RoomManagementStatus.STAY;
    }, [room]);

    const bookingStatusColor = useMemo(() => {
        if (isRoomIndicatorType) {
            switch (room.status) {
                case RoomManagementStatus.STOP_SELLING:
                    return RoomManagementStatusColor[RoomManagementStatus.STOP_SELLING];
                case RoomManagementStatus.STAY:
                    return RoomManagementStatusColor[
                        room.booking?.bookingStatus || RoomManagementStatus.VACANCY
                    ];
            }
        }

        if (isFacilityIndicatorType) {
            // finished facility booking
            // TO DO: show status color of facility booking
            switch (room.status) {
                case RoomManagementStatus.STAY:
                    return RoomManagementStatusColor[RoomManagementStatus.STAY];
            }
        }

        return '';
    }, [room, isRoomIndicatorType, isFacilityIndicatorType]);

    const bookingBackgroundColor = useMemo(() => {
        if (isRoomIndicatorType) {
            switch (room.status) {
                case RoomManagementStatus.STOP_SELLING:
                    return RoomManagementBackgroundColor[
                        RoomManagementStatus.STOP_SELLING
                    ];
                case RoomManagementStatus.STAY:
                    return RoomManagementBackgroundColor[
                        room.booking?.bookingStatus || RoomManagementStatus.VACANCY
                    ];
            }
        }
        return '';
    }, [room, isRoomIndicatorType]);

    const isStopSelling = useMemo(() => {
        return room?.status === RoomManagementStatus.STOP_SELLING;
    }, [room]);

    const isVacancy = useMemo(() => {
        return room?.status === RoomManagementStatus.VACANCY;
    }, [room]);

    const isShowDropBox = useMemo(() => {
        return (
            isDraggingBooking && isVacancy && validRooms.includes(room?.roomType?.id || 0)
        );
    }, [isDraggingBooking, isVacancy, validRooms, room]);

    const renderEmptyCell = () => {
        return <div className="empty-cell"></div>;
    };

    const renderCleaningStatus = (cleaningStatus: string | undefined) => {
        if (cleaningStatus === 'cleaned') {
            return (
                <span className="room-clean text-success">
                    <CleanRoomIcon />
                    {t('roomManagement.list.cleaned')}
                </span>
            );
        } else if (cleaningStatus === 'uncleaned') {
            return (
                <span className="room-clean text-danger">
                    <WarningFilled />
                    {t('roomManagement.list.notCleaned')}
                </span>
            );
        }
        return <></>;
    };

    const onCloseModal = () => {
        setTimeout(() => {
            setIsShowDetail(false);
        }, 0);
    };

    const totalPrice = useMemo(() => {
        if (isRoomIndicatorType) return room?.individualUnpaidAmount || 0;
        if (isFacilityIndicatorType) {
            return sumBy(bookings, 'totalAmount');
        }
        return 0;
    }, [room, bookings, isFacilityIndicatorType, isRoomIndicatorType]);

    const numberOfNightStay = useMemo(() => {
        if (room?.booking) {
            const stayingStartDate = parseDate(
                room?.booking.startDateOfStay,
            )?.fmYYYYMMDD();
            const stayingEndDate = parseDate(room?.booking.endDateOfStay)?.fmYYYYMMDD();
            const numberOfNight =
                parseDate(currentDate)?.diff(parseDate(stayingStartDate), 'day') + 1;
            const numberOfNightsStayed = parseDate(stayingEndDate)?.diff(
                parseDate(stayingStartDate),
                'day',
            );
            return `${numberOfNight}/${numberOfNightsStayed}`;
        }
    }, [room]);

    const getRepresentativeGuestName = (name = '') => {
        return isHotelCleaner ? '*****' : name;
    };

    return (
        <div className="room-management-setting-cell-wrapper">
            {!room && renderEmptyCell()}
            {!!room && (
                <Spin size="small" spinning={assignRoomLoading === room.id}>
                    <div
                        className={classNames({
                            hover: isShowDetail,
                            'assigned-cell': true,
                            'drop-able': isShowDropBox,
                            'opacity-room': isOpacity,
                        })}
                        style={{
                            backgroundColor: bookingBackgroundColor,
                        }}
                        onClick={() => {
                            if (isFacilityIndicatorType && bookings.length > 1) {
                                setIsShowDetail(false);
                                return;
                            }
                            setIsShowDetail(true);
                        }}
                    >
                        <div
                            className={classNames('room-status')}
                            style={{
                                backgroundColor: bookingStatusColor,
                            }}
                        ></div>
                        <div className="name text-truncate">
                            <TextTruncate text={room?.name} placement="top" />
                        </div>
                        <div className="desc text-truncate">{room?.desc}</div>
                        {bookings.length > 1 && (
                            <BookingList
                                bookings={bookings}
                                isOnlyShowMore={true}
                                facilityTypeName={room.name}
                                onChangeStatusSuccess={onChangeStatusSuccess}
                            />
                        )}
                        {isStay && (
                            <>
                                <div className="guest text-truncate">
                                    {isFacilityIndicatorType && bookings.length === 1
                                        ? bookings[0]?.guest?.yomigana
                                        : getRepresentativeGuestName(
                                              room.booking?.representativeGuest?.yomigana,
                                          )}
                                </div>
                                <div className="price text-truncate">
                                    ¥{formatMoney(max([totalPrice, 0]) || 0)}
                                </div>
                            </>
                        )}
                        {hotelStored?.showCleaningStatus && (
                            <div>{renderCleaningStatus(room?.cleaningStatus)}</div>
                        )}
                        {isVacancy && (
                            <>
                                <RoomVacancyDetail
                                    isOpen={isShowDetail}
                                    onClose={onCloseModal}
                                    room={room}
                                    isReadOnly={isReadOnly}
                                />
                            </>
                        )}
                        {isStay && isRoomIndicatorType && (
                            <DetailBookingModal
                                isOpen={isShowDetail}
                                booking={convertToBookingSchedule({
                                    ...room.booking,
                                    price: room.price,
                                    room: room as IBasicItem,
                                    roomTypeId: room.roomType?.id,
                                    roomType: room.roomType,
                                    individualUnpaidAmount:
                                        room?.individualUnpaidAmount || 0,
                                } as IUnassignedBookingItem)}
                                onClose={onCloseModal}
                                onUnassignBookingSuccess={(booking) => {
                                    const ids = [booking.id];
                                    dispatch(unassignedBookingSuccess(ids));
                                    dispatch(getBookingUnassignedList());
                                    dispatch(setAssignRoomLoading(null));
                                }}
                                onUnassignBooking={(booking) => {
                                    dispatch(
                                        setAssignRoomLoading(booking.room?.id || null),
                                    );
                                }}
                                onUnassignBookingError={() => {
                                    dispatch(setAssignRoomLoading(null));
                                }}
                                navigate={navigate}
                                needGetPrice={false}
                                onChangeBookingStatus={(booking) => {
                                    if (
                                        booking.status ===
                                            RoomBookingItemBookingStatus.NOT_ARRIVED ||
                                        booking.status ===
                                            RoomBookingItemBookingStatus.CHECKED_OUT
                                    ) {
                                        dispatch(
                                            changeBookingStatusSuccess({
                                                bookingId: booking?.id,
                                                status: booking.status,
                                            }),
                                        );
                                    }
                                    if (
                                        booking.status ===
                                        RoomBookingItemBookingStatus.CANCELLED
                                    ) {
                                        dispatch(
                                            removeBookingFromAssignList({
                                                ids: [booking.id],
                                            }),
                                        );
                                    }
                                }}
                                isReadOnly={isReadOnly}
                            />
                        )}

                        {isStay && isFacilityIndicatorType && bookings.length === 1 && (
                            <BookingDetail
                                booking={bookings[0]}
                                isOpen={isShowDetail}
                                onClose={onCloseModal}
                                navigate={navigate}
                                onChangeStatusSuccess={(booking) => {
                                    if (onChangeStatusSuccess)
                                        onChangeStatusSuccess(booking);
                                }}
                                isReadOnly={isReadOnly}
                            />
                        )}
                        {isStopSelling && (
                            <RoomStopSellingDetail
                                isOpen={isShowDetail}
                                onClose={onCloseModal}
                                room={room}
                                isReadOnly={isReadOnly}
                            />
                        )}
                        {room?.booking && (
                            <div
                                className={classNames('number-of-night')}
                                style={{ color: bookingStatusColor }}
                            >
                                {numberOfNightStay}
                            </div>
                        )}
                    </div>
                </Spin>
            )}
            {isShowDropBox && (
                <DropBox x={columnIndex} y={rowIndex} roomId={room?.id || 0} />
            )}
        </div>
    );
};
