import {
    BarChartOutlined,
    CalendarOutlined,
    ClusterOutlined,
    DesktopOutlined,
    KeyOutlined,
    LineChartOutlined,
    ReadOutlined,
    RiseOutlined,
    ScheduleOutlined,
    TeamOutlined,
    AccountBookOutlined,
    GatewayOutlined,
} from '@ant-design/icons';
import { AdminGroup, TabistTeamGroup, UserGroup } from '~common/constants';
import i18next from '~plugins/i18next/i18n';

export enum MainMenu {
    COUPON = 'coupon',
    PMS = 'pms',
    PRICING = 'pricing',
}

export enum PmsMenu {
    DASHBOARD = 'dashboard',
    ROOM_BOOKING = 'roomBooking',
    FACILITY_RESERVATION = 'facilityReservation',
    GUESTS = 'guests',
    ROOM_MANAGEMENT = 'roomManagement',
    REPORT = 'report',
    CLOSING_BOOKING = 'closingBooking',
    FRONT_SETTING = 'frontSetting',
    BO_SETTING = 'backOfficeSetting',
    EXTERNAL_TOOLS = 'integrations',
}

export enum PmsFrontSettingMenu {
    HOTEL_SETTING = 'hotelSetting',
    PLAN_SETTING = 'planSetting',
    PLAN_RANK = 'pms',
    ROOM_TYPE_SETTING = 'roomTypeSetting',
    ROOM_SETTING = 'roomSetting',
    INDICATOR_SETTING = 'indicatorSetting',
    EQUIPMENT_TYPE_SETTING = 'equipmentTypeSetting',
    EQUIPMENT_SETTING = 'equipmentSetting',
    SALE_ITEM_SETTING = 'saleItemSetting',
    SALE_GROUP_SETTING = 'saleGroupSetting',
    PAYMENT_METHOD_SETTING = 'paymentMethodSetting',
    MARKETING_CHANNEL_SETTING = 'marketingChannelSetting',
    MARKETING_CHANNEL_GROUP_SETTING = 'marketingChannelGroupSetting',
    GUEST_CLASSIFICATION_SETTINGS = 'guestClassificationSettings',
    INVENTORY_BREAKDOWN_MANAGEMENT = 'inventoryBreakdownManagement',
    TAX_SETTING = 'taxSetting',
    CHILDREN_TYPE_SETTING = 'childrenTypeSetting',
    SITE_CONTROLLER = 'siteController',
    HISTORY = 'history',
}

export enum PmsBOSettingMenu {
    PRIVILEGE_MANAGEMENT = 'privilegeManagement',
    SMTP_SETTING = 'smtpSetting',
    OPERATION_HISTORY = 'operationHistory',
    NOTIFICATION_SETTING = 'notificationSetting',
    CSV_BOOKING_UPLOAD = 'csvBookingUpload',
}

export enum PmsRoomBookingMenu {
    LIST = 'list',
    SCHEDULE = 'schedule',
}

export enum PmsBOSettingSMTPMenu {
    MAIL_SERVER_SETTING = 'mailServerSetting',
    SYSTEM_INTEGRATION_SETTING = 'systemIntegrationSettting',
}

export enum PmsFacilityBookingMenu {
    LIST = 'facility-booking-list',
    SCHEDULE = 'facility-schedule',
}

export enum PmsReportMenu {
    SUMMARY_REPORT = 'summary_report',
    PAYMENT_REPORT = 'payment_report',
    SALE_SUMMARY_REPORT = 'sale_summary_report',
    SALES_PAYMENT_REPORT = 'sale_payment_report',
    SALES_BY_CATEGORY_REPORT = 'sale_by_category_report',
    UNPAID_DETAIL_REPORT = 'unpaid_detail_report',
    PAYMENT_SUMMARY_REPORT = 'payment_summary_report',
    CANCELLATION_REPORT = 'cancelation_report',
    OPERATION_STATUS_REPORT = 'operation_status_report',
    PAYMENT_DETAILS_REPORT = 'payment_details_report',
    LOCAL_TAX_DETAIL_REPORT = 'local_tax_detail_report',
    CURRENT_GUEST_REPORT = 'current-guest_report',
    MEAL_REPORT = 'meal_report',
    SALES_BY_PLANS_REPORT = 'sales_by_plans_report',
    SALES_BY_OTA_REPORT = 'sales_by_ota_report',
}

export enum PmsIntegrationSettingMenu {
    HOTEL_SMART = 'hotel_smart',
    KIOSK_ALMEX = 'kiosk_almex',
    KEYCARD_SETTING = 'keycard_setting',
}

export enum PMSFrontSettingsCategoryKey {
    ROOM_EQUIPMENT_SETTINGS = 'roomEquipmentSettings',
    PLAN_SETTINGS = 'planSettings',
    SALES_DEPOSIT_TAX_SETTINGS = 'salesDepositTaxSettings',
    CHANNEL_SETTINGS = 'channelSettings',
    OTHERS = 'others',
}

export enum PMSReportCategoryKey {
    EARNINGS = 'earnings',
    DEPOSITS_WITHDRAWAL = 'depositAndWithdrawal',
    OTHERS = 'others',
}

export const roomEquipmentSettingsMenu = [
    {
        label: i18next.t('common.sidebar.pms.menus.frontSetting.submenu.roomTypeSetting'),
        key: PmsFrontSettingMenu.ROOM_TYPE_SETTING,
        to: '/front-settings/room-type',
        permissions: [...TabistTeamGroup],
    },
    {
        label: i18next.t('common.sidebar.pms.menus.frontSetting.submenu.roomSetting'),
        key: PmsFrontSettingMenu.ROOM_SETTING,
        to: '/front-settings/room',
        permissions: [...TabistTeamGroup],
    },
    {
        label: i18next.t(
            'common.sidebar.pms.menus.frontSetting.submenu.indicatorSetting',
        ),
        key: PmsFrontSettingMenu.INDICATOR_SETTING,
        to: '/front-settings/indicator',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
            UserGroup.HOTEL_CLEANING,
        ],
    },
    {
        label: i18next.t(
            'common.sidebar.pms.menus.frontSetting.submenu.equipmentTypeSetting',
        ),
        key: PmsFrontSettingMenu.EQUIPMENT_TYPE_SETTING,
        to: '/front-settings/facility-type',
        permissions: [...AdminGroup, UserGroup.HOTEL_OWNER, UserGroup.HOTEL_OPERATOR],
    },
    {
        label: i18next.t(
            'common.sidebar.pms.menus.frontSetting.submenu.equipmentSetting',
        ),
        key: PmsFrontSettingMenu.EQUIPMENT_SETTING,
        to: '/front-settings/facility',
        permissions: [...AdminGroup, UserGroup.HOTEL_OWNER, UserGroup.HOTEL_OPERATOR],
    },
];

export const planSettingsMenu = [
    {
        label: i18next.t('common.sidebar.pms.menus.frontSetting.submenu.planSetting'),
        key: PmsFrontSettingMenu.PLAN_SETTING,
        to: '/front-settings/plan',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_FRONT,
            UserGroup.HOTEL_OPERATOR,
        ],
    },
    {
        label: i18next.t('common.sidebar.pms.menus.frontSetting.submenu.planRank'),
        key: PmsFrontSettingMenu.PLAN_RANK,
        to: '/front-settings/rank-calendar',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_FRONT,
            UserGroup.HOTEL_OPERATOR,
        ],
    },
    {
        label: i18next.t(
            'common.sidebar.pms.menus.frontSetting.submenu.childrenTypeSetting',
        ),
        key: PmsFrontSettingMenu.CHILDREN_TYPE_SETTING,
        to: '/front-settings/children-type',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ],
    },
];

export const salesDepositTaxSettingsMenu = [
    {
        label: i18next.t('common.sidebar.pms.menus.frontSetting.submenu.saleItemSetting'),
        key: PmsFrontSettingMenu.SALE_ITEM_SETTING,
        to: '/front-settings/sale-item',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ],
    },
    {
        label: i18next.t(
            'common.sidebar.pms.menus.frontSetting.submenu.saleGroupSetting',
        ),
        key: PmsFrontSettingMenu.SALE_GROUP_SETTING,
        to: '/front-settings/sale-group',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ],
    },
    {
        label: i18next.t(
            'common.sidebar.pms.menus.frontSetting.submenu.paymentMethodSetting',
        ),
        key: PmsFrontSettingMenu.PAYMENT_METHOD_SETTING,
        to: '/front-settings/payment-method',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ],
    },
    {
        label: i18next.t('common.sidebar.pms.menus.frontSetting.submenu.taxSetting'),
        key: PmsFrontSettingMenu.TAX_SETTING,
        to: '/front-settings/tax',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ],
    },
];

export const channelSettingsMenu = [
    {
        label: i18next.t(
            'common.sidebar.pms.menus.frontSetting.submenu.marketingChannelSetting',
        ),
        key: PmsFrontSettingMenu.MARKETING_CHANNEL_SETTING,
        to: '/front-settings/marketing-channel',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ],
    },
    {
        label: i18next.t(
            'common.sidebar.pms.menus.frontSetting.submenu.marketingChannelGroupSetting',
        ),
        key: PmsFrontSettingMenu.MARKETING_CHANNEL_GROUP_SETTING,
        to: '/front-settings/marketing-channel-group',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ],
    },
];

export const othersSettingsMenu = [
    {
        label: i18next.t('common.sidebar.pms.menus.frontSetting.submenu.hotelSetting'),
        key: PmsFrontSettingMenu.HOTEL_SETTING,
        to: '/front-settings/hotel-setting',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_FRONT,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_CLEANING,
        ],
    },
    {
        label: i18next.t('common.sidebar.pms.menus.frontSetting.submenu.siteController'),
        key: PmsFrontSettingMenu.SITE_CONTROLLER,
        to: '/front-settings/site-controller',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ],
    },
    {
        label: i18next.t('common.sidebar.pms.menus.frontSetting.submenu.history'),
        key: PmsFrontSettingMenu.HISTORY,
        to: '/front-settings/operation-history',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ],
    },
    {
        label: i18next.t(
            'common.sidebar.pms.menus.frontSetting.submenu.guestClassificationSettings',
        ),
        key: PmsFrontSettingMenu.GUEST_CLASSIFICATION_SETTINGS,
        to: '/front-settings/guest-classification',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ],
    },
    {
        label: i18next.t(
            'common.sidebar.pms.menus.frontSetting.submenu.inventoryBreakdownManagement',
        ),
        key: PmsFrontSettingMenu.INVENTORY_BREAKDOWN_MANAGEMENT,
        to: '/front-settings/inventory',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ],
    },
];

export const pmsFrontSettings = [
    {
        label: i18next.t(
            'common.sidebar.pms.menus.frontSetting.category.roomEquipmentSettings',
        ),
        key: PMSFrontSettingsCategoryKey.ROOM_EQUIPMENT_SETTINGS,
        type: 'group',
        children: roomEquipmentSettingsMenu,
    },
    {
        label: i18next.t('common.sidebar.pms.menus.frontSetting.category.planSettings'),
        key: PMSFrontSettingsCategoryKey.PLAN_SETTINGS,
        type: 'group',
        children: planSettingsMenu,
    },
    {
        label: i18next.t(
            'common.sidebar.pms.menus.frontSetting.category.salesDepositTaxSettings',
        ),
        key: PMSFrontSettingsCategoryKey.SALES_DEPOSIT_TAX_SETTINGS,
        type: 'group',
        children: salesDepositTaxSettingsMenu,
    },
    {
        label: i18next.t(
            'common.sidebar.pms.menus.frontSetting.category.channelSettings',
        ),
        key: PMSFrontSettingsCategoryKey.CHANNEL_SETTINGS,
        type: 'group',
        children: channelSettingsMenu,
    },
    {
        label: i18next.t('common.sidebar.pms.menus.frontSetting.category.others'),
        key: PMSFrontSettingsCategoryKey.OTHERS,
        type: 'group',
        children: othersSettingsMenu,
    },
];

export const smtpSettings = [
    {
        label: i18next.t(
            'common.sidebar.pms.menus.boSetting.submenu.smtpSetting.submenu.mailServerSetting',
        ),
        key: PmsBOSettingSMTPMenu.MAIL_SERVER_SETTING,
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_FRONT,
            UserGroup.HOTEL_OPERATOR,
        ],
        to: '/bo-settings/general-setting/smtp',
    },
];

export const pmsBOSettings = [
    {
        label: i18next.t(
            'common.sidebar.pms.menus.boSetting.submenu.privilegeManagement',
        ),
        key: PmsBOSettingMenu.PRIVILEGE_MANAGEMENT,
        to: '/bo-settings/hotel',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_FRONT,
            UserGroup.HOTEL_OPERATOR,
        ],
    },
    {
        label: i18next.t('common.sidebar.pms.menus.boSetting.submenu.smtpSetting.title'),
        key: PmsBOSettingMenu.SMTP_SETTING,
        children: smtpSettings,
        to: '/',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.boSetting.submenu.operationHistory'),
        key: PmsBOSettingMenu.OPERATION_HISTORY,
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_FRONT,
            UserGroup.HOTEL_OPERATOR,
        ],
        to: '/bo-settings/operation-history',
    },
    {
        label: i18next.t(
            'common.sidebar.pms.menus.boSetting.submenu.notificationSetting',
        ),
        key: PmsBOSettingMenu.NOTIFICATION_SETTING,
        to: '/bo-settings/notification',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_FRONT,
            UserGroup.HOTEL_OPERATOR,
        ],
    },
    {
        label: i18next.t('common.sidebar.pms.menus.boSetting.submenu.csvBookingUpload'),
        key: PmsBOSettingMenu.CSV_BOOKING_UPLOAD,
        to: '/bo-settings/csv-booking-upload',
        permissions: [
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_FRONT,
            UserGroup.HOTEL_OPERATOR,
        ],
    },
];

export const integrationSettings = [
    {
        label: i18next.t('common.sidebar.pms.menus.integrations.submenu.hotelSmart'),
        key: PmsIntegrationSettingMenu.HOTEL_SMART,
        to: '/integrations/hotel-smart',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.integrations.submenu.kioskAlmex'),
        key: PmsIntegrationSettingMenu.KIOSK_ALMEX,
        to: '/integrations/almex-kiosk',
    },
    // ...(process.env.REACT_APP_KEYCARDS === 'true'
    //     ? [
    //           {
    //               label: i18next.t(
    //                   'common.sidebar.pms.menus.integrations.submenu.keycardSetting',
    //               ),
    //               key: PmsIntegrationSettingMenu.KEYCARD_SETTING,
    //               to: '/integrations/keycard-setting',
    //           },
    //       ]
    //     : []),
];

export const mainMenus = [
    // {
    //     label: i18next.t('common.sidebar.coupon'),
    //     key: MainMenu.COUPON,
    //     icon: <TagsOutlined />,
    // },
    {
        label: i18next.t('common.sidebar.pms.title'),
        key: MainMenu.PMS,
        icon: <BarChartOutlined />,
    },
    {
        label: i18next.t('common.sidebar.pricing'),
        key: MainMenu.PRICING,
        icon: <AccountBookOutlined />,
    },
];

export const pmsRoomBookingMenus = [
    {
        label: i18next.t('common.sidebar.pms.menus.roomBooking.submenu.list'),
        key: PmsRoomBookingMenu.LIST,
        to: '/room-booking',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.roomBooking.submenu.schedule'),
        key: PmsRoomBookingMenu.SCHEDULE,
        to: '/room-booking/schedule',
    },
];

export const pmsFacilityBookingMenus = [
    {
        label: i18next.t('common.sidebar.pms.menus.facility.submenu.list'),
        key: PmsFacilityBookingMenu.LIST,
        to: '/facility-booking',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.facility.submenu.schedule'),
        key: PmsFacilityBookingMenu.SCHEDULE,
        to: '/facility-booking/schedule',
    },
];

export const earningsReportMenu = [
    // Temporarily removing access to Summary Page
    // {
    //     label: i18next.t('common.sidebar.pms.menus.report.submenu.summary'),
    //     key: PmsReportMenu.SUMMARY_REPORT,
    //     to: '/report/summary',
    // },
    {
        label: i18next.t('common.sidebar.pms.menus.report.submenu.saleSummary'),
        key: PmsReportMenu.SALE_SUMMARY_REPORT,
        to: '/report/sale',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.report.submenu.salesByPlansReport'),
        key: PmsReportMenu.SALES_BY_PLANS_REPORT,
        to: '/report/sales-by-plans-report',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.report.submenu.salesByOtaReport'),
        key: PmsReportMenu.SALES_BY_OTA_REPORT,
        to: '/report/sales-by-ota-report',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.report.submenu.salesByCategory'),
        key: PmsReportMenu.SALES_BY_CATEGORY_REPORT,
        to: '/report/sales-by-category',
    },
];

export const depositsAndWithdrawalReportMenu = [
    {
        label: i18next.t('common.sidebar.pms.menus.report.submenu.salesPayment'),
        key: PmsReportMenu.SALES_PAYMENT_REPORT,
        to: '/report/sales-payment',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.report.submenu.paymentSummary'),
        key: PmsReportMenu.PAYMENT_SUMMARY_REPORT,
        to: '/report/payment-summary',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.report.submenu.paymentDetails'),
        key: PmsReportMenu.PAYMENT_DETAILS_REPORT,
        to: '/report/payment-details',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.report.submenu.unpaidDetail'),
        key: PmsReportMenu.UNPAID_DETAIL_REPORT,
        to: '/report/unpaid-detail',
    },
];

export const othersReportMenu = [
    {
        label: i18next.t('common.sidebar.pms.menus.report.submenu.currentGuest'),
        key: PmsReportMenu.CURRENT_GUEST_REPORT,
        to: '/report/current-guest',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.report.submenu.cancellation'),
        key: PmsReportMenu.CANCELLATION_REPORT,
        to: '/report/cancellation',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.report.submenu.operationStatus'),
        key: PmsReportMenu.OPERATION_STATUS_REPORT,
        to: '/report/operation-status',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.report.submenu.mealReport'),
        key: PmsReportMenu.MEAL_REPORT,
        to: '/report/meal-report',
    },
    {
        label: i18next.t('common.sidebar.pms.menus.report.submenu.taxDetail'),
        key: PmsReportMenu.LOCAL_TAX_DETAIL_REPORT,
        to: '/report/local-tax',
    },
];

export const pmsReportMenus = [
    {
        label: i18next.t('common.sidebar.pms.menus.report.category.earnings'),
        key: PMSReportCategoryKey.EARNINGS,
        type: 'group',
        children: earningsReportMenu,
    },
    {
        label: i18next.t('common.sidebar.pms.menus.report.category.deposits'),
        key: PMSReportCategoryKey.DEPOSITS_WITHDRAWAL,
        type: 'group',
        children: depositsAndWithdrawalReportMenu,
    },
    {
        label: i18next.t('common.sidebar.pms.menus.report.category.others'),
        key: PMSReportCategoryKey.OTHERS,
        type: 'group',
        children: othersReportMenu,
    },
];

export const pmsMenus = (isMobile: boolean) => {
    const wideSubmenuPopupClass = isMobile
        ? 'wide-submenu_popup isMobile'
        : 'wide-submenu_popup';
    return [
        {
            label: i18next.t('common.sidebar.pms.menus.dashboard.title'),
            key: PmsMenu.DASHBOARD,
            icon: <LineChartOutlined />,
            to: '/dashboard',
            requireHotel: true,
            permissions: [
                ...AdminGroup,
                UserGroup.HOTEL_OWNER,
                UserGroup.HOTEL_FRONT,
                UserGroup.HOTEL_OPERATOR,
            ],
        },
        {
            label: i18next.t('common.sidebar.pms.menus.roomBooking.title'),
            key: PmsMenu.ROOM_BOOKING,
            icon: <CalendarOutlined />,
            children: pmsRoomBookingMenus,
            permissions: [
                ...AdminGroup,
                UserGroup.HOTEL_OWNER,
                UserGroup.HOTEL_FRONT,
                UserGroup.HOTEL_OPERATOR,
            ],
            requireHotel: true,
        },
        {
            label: i18next.t('common.sidebar.pms.menus.facility.title'),
            key: PmsMenu.FACILITY_RESERVATION,
            icon: <ClusterOutlined />,
            children: pmsFacilityBookingMenus,
            to: '/facility-booking',
            permissions: [
                ...AdminGroup,
                UserGroup.HOTEL_OWNER,
                UserGroup.HOTEL_FRONT,
                UserGroup.HOTEL_OPERATOR,
            ],
            requireHotel: true,
        },
        {
            label: i18next.t('common.sidebar.pms.menus.guests.title'),
            key: PmsMenu.GUESTS,
            icon: <TeamOutlined />,
            to: '/guest',
            permissions: [
                ...AdminGroup,
                UserGroup.HOTEL_OWNER,
                UserGroup.HOTEL_FRONT,
                UserGroup.HOTEL_OPERATOR,
            ],
            requireHotel: true,
        },
        {
            label: i18next.t('common.sidebar.pms.menus.roomManagement.title'),
            key: PmsMenu.ROOM_MANAGEMENT,
            icon: <KeyOutlined />,
            to: '/room-management',
            permissions: [
                ...AdminGroup,
                UserGroup.HOTEL_OWNER,
                UserGroup.HOTEL_FRONT,
                UserGroup.HOTEL_OPERATOR,
                UserGroup.HOTEL_CLEANING,
            ],
            requireHotel: true,
        },
        {
            label: i18next.t('common.sidebar.pms.menus.report.title'),
            key: PmsMenu.REPORT,
            icon: <RiseOutlined />,
            popupClassName: wideSubmenuPopupClass,
            requireHotel: true,
            permissions: [
                ...AdminGroup,
                UserGroup.HOTEL_OWNER,
                UserGroup.HOTEL_FRONT,
                UserGroup.HOTEL_OPERATOR,
            ],
            children: pmsReportMenus,
        },
        {
            label: i18next.t('common.sidebar.pms.menus.closingBooking.title'),
            key: PmsMenu.CLOSING_BOOKING,
            icon: <ScheduleOutlined />,
            to: '/closing-booking',
            requireHotel: true,
            permissions: [
                ...AdminGroup,
                UserGroup.HOTEL_OWNER,
                UserGroup.HOTEL_FRONT,
                UserGroup.HOTEL_OPERATOR,
            ],
        },
        {
            label: i18next.t('common.sidebar.pms.menus.frontSetting.title'),
            key: PmsMenu.FRONT_SETTING,
            icon: <ReadOutlined />,
            popupClassName: wideSubmenuPopupClass,
            children: pmsFrontSettings,
            requireHotel: true,
            permissions: [
                ...AdminGroup,
                UserGroup.HOTEL_OWNER,
                UserGroup.HOTEL_FRONT,
                UserGroup.HOTEL_OPERATOR,
            ],
        },
        {
            label: i18next.t('common.sidebar.pms.menus.integrations.title'),
            key: PmsMenu.EXTERNAL_TOOLS,
            icon: <GatewayOutlined />,
            permissions: [...AdminGroup, UserGroup.HOTEL_OWNER, UserGroup.HOTEL_OPERATOR],
            requireHotel: false,
            children: integrationSettings,
        },
        {
            label: i18next.t('common.sidebar.pms.menus.boSetting.title'),
            key: PmsMenu.BO_SETTING,
            icon: <DesktopOutlined />,
            children: pmsBOSettings,
            permissions: [...TabistTeamGroup],
            requireHotel: false,
        },
    ];
};
